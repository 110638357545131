<template>
  <a-card :title="$t(title)" class="pb-2">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row class="pt-0 pb-2">
          <v-col cols="12" :sm="cols" :xl="cols" class="py-0 text-center">
            <a-ellipse
              :done="ranking_year"
              :percent="100 - ranking_year * 10"
              :custom_value="ranking_year_value"
              :text="'/' + ranking_total"
              :size="$vuetify.breakpoint.xsOnly ? 120 : 170"
              :increment="ranking_year_inc"
              :vs_value="ranking_year_vs"
              :kpi="'ranking'"
              :icon="ranking_kpi_icon"
            ></a-ellipse>
            <a-label class="mt-2 text-center">
              {{ $t("ranking_avg") }}
            </a-label>
            <v-btn
              outlined
              rounded
              color="info"
              @click="goToRatePage"
              class="mt-2 mb-1"
              >{{ $t("to_review") }}</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            :sm="cols"
            :xl="cols"
            class="py-0 text-center"
            v-if="show_rate_web && daily_rates.extras.parities_resume['web']"
          >
            <a-parity-resume-graph
              :icon="'mdi-web'"
              :values="daily_rates.extras.parities_resume['web']"
              kpi="ranking"
              prefix="parity_pie_"
            ></a-parity-resume-graph>
            <a-label class="mt-2 text-center"> {{ $t("parity") }} WEB </a-label>
            <v-btn
              outlined
              rounded
              color="info"
              @click="goToRatePage"
              class="mt-2 mb-1"
              >{{ $t("to_review") }}</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            :sm="cols"
            :xl="cols"
            class="py-0 text-center"
            v-if="show_parity && daily_rates.extras.parities_resume['ota_meta']"
          >
            <a-parity-resume-graph
              :icon="'mdi-google'"
              :values="daily_rates.extras.parities_resume['ota_meta']"
              kpi="ranking"
              prefix="parity_channel_pie_"
            ></a-parity-resume-graph>
            <a-label class="mt-2 text-center">
              {{ $t("parity") }} OTA/Meta
            </a-label>
            <v-btn
              outlined
              rounded
              color="info"
              @click="goToParityPage"
              class="mt-2 mb-1"
              >{{ $t("to_review") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-alert
          :color="out_of_market == 0 ? 'green' : 'red'"
          text
          dense
          class="white--text mt-3 mb-0 mx-2 px-6 text-left"
        >
          <v-row align="center">
            <v-col class="shrink py-0">
              <span class="text-h4">
                {{ out_of_market }}
              </span>
            </v-col>
            <v-col class="grow py-0 pl-0" v-if="out_of_market == 1">
              {{ $t("day_out_of_market") }}
            </v-col>
            <v-col class="grow py-0 pl-0" v-else>
              {{ $t("days_out_of_market") }}
            </v-col>
            <v-col class="shrink py-0">
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                outlined
                rounded
                color="red"
                @click="goToRatePage"
                v-if="out_of_market > 0"
              >
                {{ $t("see") }}</v-btn
              >
              <v-icon v-else small color="green">mdi-check</v-icon>
            </v-col>
          </v-row>
        </v-alert>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader class="mt-2" type="text"></v-skeleton-loader>
        <v-row>
          <v-col cols="4" class="px-8">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="4" class="px-8">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="4" class="px-8">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aCard from "@/components/aCard";
import aLabel from "@/components/aLabel";
import aBaseComponent from "@/components/aBaseComponent";
import aParityResumeGraph from "@/components/parity/aParityResumeGraph";
import aEllipse from "@/components/aEllipse";
import * as Utils from "@/utils.js";

export default {
  name: "a-parity-dashboard-resume",
  components: {
    aCard,
    aLabel,
    aBaseComponent,
    aEllipse,
    aParityResumeGraph
  },
  data: vm => ({
    title: "to_highlight",
    subscribed_modules: [
      "rate_daily_rates",
      "highlight_list",
      "parity_webs",
      "bi_report"
    ]
  }),
  methods: {
    goToParityPage() {
      this.$router.push({
        name: "parity",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        }
      });
    },
    goToRatePage() {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        }
      });
    },
    getRankingAvg(report) {
      if (!report || Object.values(report).length === 0) {
        return null;
      }
      let rankings = Object.values(report).map(item => item[0].ranking);
      return (
        Math.round(
          (rankings.reduce((prev, curr) => prev + curr, 0) / rankings.length) *
            10
        ) / 10
      );
    },
    getParities(report, web_report) {
      const parities = [];
      for (var [key, day] of Object.entries(report)) {
        if (web_report && web_report[key]) {
          parities.push(
            Utils.getParity(web_report[key][0].min_price, day[0].min_price)
          );
        }
      }
      return parities;
    }
  },
  mounted() {},
  computed: {
    ranking_kpi_icon() {
      return this.get_kpi("ranking").icon;
    },
    ranking_kpi_type() {
      return this.get_kpi("ranking").data_format;
    },
    ranking_kpi_sign() {
      return this.get_kpi("ranking").inverse ? -1 : 1;
    },
    parity_kpi_type() {
      return this.get_kpi("parity").data_format;
    },
    ranking_year() {
      if (this.groupDisplay) {
        return this.ranking_year_group;
      } else {
        return this.getRankingAvg(this.report);
      }
    },
    ranking_year_group() {
      let rankings = Object.entries(this.daily_rates.report)
        .map(item =>
          item[0].startsWith("competitor::")
            ? this.getRankingAvg(item[1])
            : null
        )
        .filter(item => item !== null);
      return (
        Math.round(
          (rankings.reduce((prev, curr) => prev + curr, 0) / rankings.length) *
            10
        ) / 10
      );
    },
    ranking_year_value() {
      return Utils.format_kpi_value(
        this.ranking_year,
        this.ranking_kpi_type,
        this.l
      );
    },
    ranking_year_vs() {
      return this.getRankingAvg(this.data_to_compare);
    },
    ranking_year_inc() {
      return Utils.getIncrement(
        this.ranking_year_vs,
        this.ranking_year,
        this.ranking_kpi_sign
      );
    },
    ranking_total() {
      if (this.groupDisplay) {
        return 10;
      }
      return this.competitors.length;
    },
    highlights_out_of_market() {
      return this.highlights.filter(
        item =>
          item.section == "rate" &&
          item.literal_type_name.startsWith("rate_min_price_different") &&
          item.priority == 3
      );
    },
    out_of_market() {
      return this.highlights_out_of_market.length;
    },
    report() {
      return this.getHotelFromCompetitors && this.daily_rates.report
        ? this.daily_rates.report[
            "competitor::" + this.getHotelFromCompetitors.id
          ]
        : [];
    },
    data_to_compare() {
      return this.getHotelFromCompetitors && this.daily_rates.data_to_compare
        ? this.daily_rates.data_to_compare[
            "competitor::" + this.getHotelFromCompetitors.id
          ]
        : [];
    },
    web_report() {
      return this.hotel_web && this.daily_rates.report
        ? this.daily_rates.report["competitor::" + this.hotel_web.id]
        : [];
    },
    web_data_to_compare() {
      return this.hotel_web && this.daily_rates.data_to_compare
        ? this.daily_rates.data_to_compare["competitor::" + this.hotel_web.id]
        : [];
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    hotel_web() {
      return this.webs.length ? this.webs[0] : null;
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    show_parity() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.parity &&
            this.current_hotel.permissions.parity
        : false;
    },
    cols() {
      return this.show_rate_web && this.show_parity ? 4 : 6;
    },
    ...mapState({
      bi_report: state => state.bi.report,
      competitors: state => state.rate.competitors,
      webs: state => state.parity.webs,
      parity_steps: state => state.parity_steps,
      daily_rates: state => state.rate.daily_rates,
      app_width: state => state.app_width,
      highlights: state => state.highlight.list
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      getHotelFromCompetitors: "getHotelFromCompetitors",
      l: "current_hotel_language",
      get_kpi: "get_kpi"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
