<template>
  <a-page :image_source="image_source" :image_height="image_height">
    <template v-slot:header_options>
      <v-tooltip bottom dark color="#333333" v-if="current_hotel">
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" color="white" outlined small>
            <v-icon small class="mr-1">mdi-clock-outline</v-icon>
            {{ current_hotel.last_import_date.dashboard | formatDatetime }}
          </v-chip>
        </template>
        <span>
          {{ $t("last_update") }}
        </span>
      </v-tooltip>
    </template>
    <dashboard-rate-app ref="dashboardrate"></dashboard-rate-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import dashboardRateApp from "@/components/app/dashboardRateApp";

export default {
  name: "rate",
  components: {
    aPage,
    dashboardRateApp
  },
  data: () => ({
    image_source: "/img/header.jpg", // Mandatory path
    image_height: 200 // Mandatory number
  }),
  methods: {},
  watch: {},
  computed: {
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
