<template>
  <a-card :title="$t(title)" class="pb-2">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row>
          <v-col cols="12" class="py-0">
            <!-- <p class="pt-0">{{ $t("showing_highlights_progress") }}</p> -->
            <v-row>
              <v-col cols="6" class=" text-center mt-3">
                <a-ellipse
                  :done="ranking_1w"
                  :percent="100 - ranking_1w * 10"
                  :custom_value="ranking_1w_value"
                  :text="'/' + ranking_total"
                  :size="$vuetify.breakpoint.xsOnly ? 120 : 170"
                  :increment="ranking_1w_inc"
                  :vs_value="ranking_1w_vs"
                  :kpi="kpi"
                  :icon="kpi_icon"
                ></a-ellipse>
                <a-label class="mt-2 text-center">
                  {{ $t("ranking_1w") }}
                </a-label>
              </v-col>
              <v-col cols="6" class=" text-center mt-3">
                <a-ellipse
                  :done="ranking_3m"
                  :percent="100 - ranking_3m * 10"
                  :custom_value="ranking_3m_value"
                  :text="'/' + ranking_total"
                  :size="$vuetify.breakpoint.xsOnly ? 120 : 170"
                  :increment="ranking_3m_inc"
                  :vs_value="ranking_3m_vs"
                  :kpi="'ranking'"
                  :icon="kpi_icon"
                ></a-ellipse>
                <a-label class="mt-2 text-center">
                  {{ $t("ranking_3m") }}
                </a-label>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-alert
          :color="out_of_market == 0 ? 'green' : 'red'"
          text
          dense
          class="white--text mt-5 mb-0 mx-2 px-6 text-left"
        >
          <v-row align="center">
            <v-col class="shrink py-0">
              <span class="text-h4">
                {{ out_of_market }}
              </span>
            </v-col>
            <v-col class="grow py-0 pl-0" v-if="out_of_market == 1">
              {{ $t("day_out_of_market") }}
            </v-col>
            <v-col class="grow py-0 pl-0" v-else>
              {{ $t("days_out_of_market") }}
            </v-col>
            <v-col class="shrink py-0">
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                outlined
                rounded
                color="red"
                @click="goToRatePage"
                v-if="out_of_market > 0"
              >
                {{ $t("see") }}</v-btn
              >
              <v-icon v-else small color="green">mdi-check</v-icon>
            </v-col>
          </v-row>
        </v-alert>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader class="mt-2" type="text"></v-skeleton-loader>
        <v-row>
          <v-col cols="4" class="px-8">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="4" class="px-8">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="4" class="px-8">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aCard from "@/components/aCard";
import aIncrement from "@/components/aIncrement";
import aLabel from "@/components/aLabel";
import aBaseComponent from "@/components/aBaseComponent";
import aEllipseKpi from "@/components/aEllipseKpi";
import aEllipse from "@/components/aEllipse";
import * as Utils from "@/utils.js";
var numeral = require("numeral");

export default {
  name: "a-rate-dashboard-resume",
  components: {
    aCard,
    aLabel,
    aBaseComponent,
    aEllipse
  },
  data: vm => ({
    title: "to_highlight",
    subscribed_modules: ["rate_daily_rates", "highlight_list"],
    kpi: "ranking"
  }),
  methods: {
    goToRatePage() {
      const hotel = this.getHotelById(
        this.highlights_out_of_market[0].hotel_id
      );
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: this.highlights_out_of_market[0].js_function_params
      });
    },
    getRankingAvg(report) {
      if (Object.values(report).length === 0) {
        return null;
      }
      let rankings = Object.values(report).map(item => item[0].ranking);
      return (
        Math.round(
          (rankings.reduce((prev, curr) => prev + curr, 0) / rankings.length) *
            10
        ) / 10
      );
    },
    get3m(report) {
      return Object.entries(report)
        .filter(item => Vue.moment(item[0]).diff(Vue.moment(), "months") < 3)
        .map(item => item[1]);
    },
    get1w(report) {
      return Object.entries(report)
        .filter(item => Vue.moment(item[0]).diff(Vue.moment(), "days") < 7)
        .map(item => item[1]);
    }
  },
  mounted() {},
  computed: {
    report() {
      return this.getHotelFromCompetitors && this.daily_rates.report
        ? this.daily_rates.report[
            "competitor::" + this.getHotelFromCompetitors.id
          ]
        : [];
    },
    data_to_compare() {
      return this.getHotelFromCompetitors
        ? this.daily_rates.data_to_compare[
            "competitor::" + this.getHotelFromCompetitors.id
          ]
        : [];
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    kpi_icon() {
      return this.get_kpi(this.kpi).icon;
    },
    kpi_type() {
      return this.get_kpi(this.kpi).data_format;
    },
    kpi_sign() {
      return this.get_kpi(this.kpi).inverse ? -1 : 1;
    },
    ranking_year() {
      if (this.groupDisplay) {
        return this.ranking_year_group;
      } else {
        return this.getRankingAvg(this.report);
      }
    },
    ranking_year_group() {
      let rankings = Object.entries(this.daily_rates.report)
        .map(item =>
          item[0].startsWith("competitor::")
            ? this.getRankingAvg(item[1])
            : null
        )
        .filter(item => item !== null);
      return (
        Math.round(
          (rankings.reduce((prev, curr) => prev + curr, 0) / rankings.length) *
            10
        ) / 10
      );
    },
    ranking_year_value() {
      return Utils.format_kpi_value(this.ranking_year, this.kpi_type, this.l);
    },
    ranking_year_vs() {
      return this.getRankingAvg(this.data_to_compare);
    },
    ranking_year_inc() {
      return Utils.getIncrement(
        this.ranking_year_vs,
        this.ranking_year,
        this.kpi_sign
      );
    },
    ranking_3m() {
      if (this.groupDisplay) {
        return this.ranking_3m_group;
      } else {
        return this.getRankingAvg(this.get3m(this.report));
      }
    },
    ranking_3m_group() {
      let rankings = Object.entries(this.daily_rates.report)
        .map(item =>
          item[0].startsWith("competitor::")
            ? this.getRankingAvg(this.get3m(item[1]))
            : null
        )
        .filter(item => item !== null);
      return (
        Math.round(
          (rankings.reduce((prev, curr) => prev + curr, 0) / rankings.length) *
            10
        ) / 10
      );
    },
    ranking_3m_value() {
      return Utils.format_kpi_value(this.ranking_3m, this.kpi_type, this.l);
    },
    ranking_3m_vs() {
      return this.getRankingAvg(this.get3m(this.data_to_compare));
    },
    ranking_3m_inc() {
      return Utils.getIncrement(
        this.ranking_3m_vs,
        this.ranking_3m,
        this.kpi_sign
      );
    },
    ranking_1w() {
      if (this.groupDisplay) {
        return this.ranking_1w_group;
      } else {
        return this.getRankingAvg(this.get1w(this.report));
      }
    },
    ranking_1w_group() {
      let rankings = Object.entries(this.daily_rates.report)
        .map(item =>
          item[0].startsWith("competitor::")
            ? this.getRankingAvg(this.get1w(item[1]))
            : null
        )
        .filter(item => item !== null);
      return (
        Math.round(
          (rankings.reduce((prev, curr) => prev + curr, 0) / rankings.length) *
            10
        ) / 10
      );
    },
    ranking_1w_value() {
      return Utils.format_kpi_value(this.ranking_1w, this.kpi_type, this.l);
    },
    ranking_1w_vs() {
      return this.getRankingAvg(this.get1w(this.data_to_compare));
    },
    ranking_1w_inc() {
      return Utils.getIncrement(
        this.ranking_1w_vs,
        this.ranking_1w,
        this.kpi_sign
      );
    },
    ranking_total() {
      if (this.groupDisplay) {
        return 10;
      }
      return this.competitors.length;
    },
    highlights_out_of_market() {
      return this.highlights.filter(
        item =>
          item.section == "rate" &&
          item.literal_type_name.startsWith("rate_min_price_different") &&
          item.priority == 3
      );
    },
    out_of_market() {
      return this.highlights_out_of_market.length;
    },
    ...mapState({
      competitors: state => state.rate.competitors,
      daily_rates: state => state.rate.daily_rates,
      highlights: state => state.highlight.list
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getHotelFromCompetitors: "getHotelFromCompetitors",
      getHotelById: "getHotelById",
      l: "current_hotel_language",
      get_kpi: "get_kpi"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
