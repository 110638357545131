<template>
  <a-card :title="$t(title)" class="pb-2">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <template v-if="groupDisplay">
          <v-list dense class="mt-n4">
            <v-list-item class="px-0">
              <v-list-item-content class="py-1">
                <v-row class="grey--text lighten-2">
                  <v-col class="py-0" cols="3">
                    {{ $t("date") }}
                  </v-col>
                  <v-col class="py-0 text-center" cols="9">
                    {{ $t("ranking") }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-0"
              v-for="day in next_days_ranking"
              :key="'next_days_ranking_' + day.date"
            >
              <v-list-item-content class="py-1">
                <v-row>
                  <v-col class="py-0 d-flex justify-center" cols="3">
                    <v-list-item-title class="text-capitalize">
                      {{ day.friendly_date }}
                    </v-list-item-title>
                  </v-col>
                  <v-col class="py-0 d-flex justify-center" cols="9">
                    <div
                      class="d-flex"
                      style="width:100%;border-radius: 20px; overflow: hidden;"
                    >
                      <v-tooltip
                        v-for="c in day.competitors_min_price"
                        :key="'competitor_' + c.competitor_id"
                        color="black"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <div
                            v-on="on"
                            class="grow text-center pa-1"
                            :style="
                              'cursor:pointer; color: white;background-color:' +
                                getColorFromRanking(c.ranking)
                            "
                            @click="seeHotelDailyRate(c.hotel_id, day.date)"
                          >
                            {{ c.competitor_abbreviation }}
                          </div>
                        </template>
                        <v-avatar
                          :color="getColorFromRanking(c.ranking)"
                          size="21"
                          class="white--text mr-2 mb-1"
                        >
                          {{ c.ranking }}
                        </v-avatar>
                        <span>{{ c.competitor_name }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <v-list dense class="mt-n4">
            <v-list-item>
              <v-list-item-content class="py-1">
                <v-row class="grey--text lighten-2">
                  <v-col class="py-0" cols="3">
                    <v-list-item-title class="text-capitalize">
                      {{ $t("date") }}
                    </v-list-item-title>
                  </v-col>
                  <v-col class="py-0" cols="2">
                    <v-list-item-title class="text-capitalize text-center">
                      {{ $t("ranking") }}
                    </v-list-item-title>
                  </v-col>
                  <v-col class="py-0" cols="2">
                    <v-list-item-title class="text-capitalize text-center">
                      {{ $t("your_price") }}
                    </v-list-item-title>
                  </v-col>
                  <v-col class="py-0" v-if="show_parity" cols="2">
                    <v-list-item-title class="text-capitalize text-center">
                      {{ $t("disparities") }}
                    </v-list-item-title>
                  </v-col>
                  <v-col class="py-0" cols="2">
                    <v-list-item-title class="text-capitalize text-center">
                      {{ $t("competitors") }}
                    </v-list-item-title>
                  </v-col>
                  <v-col class="py-0" v-if="!show_parity" cols="3">
                    <v-list-item-title class="text-capitalize text-center">
                      {{ $t("room") }}
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="day in next_days_ranking"
              :key="'next_days_ranking_' + day.date"
              @click="seeDailyRate(day.date)"
            >
              <v-list-item-content class="py-1">
                <v-row>
                  <v-col
                    class="py-0 d-flex justify-center align-center"
                    cols="3"
                  >
                    <v-list-item-title class="text-capitalize">
                      {{ day.friendly_date }}
                    </v-list-item-title>
                  </v-col>
                  <v-col
                    class="py-0 d-flex justify-center align-center"
                    cols="2"
                  >
                    <v-avatar
                      :color="getColorFromRanking(day.ranking)"
                      size="21"
                      class="white--text"
                      v-if="day.ranking != '-'"
                    >
                      {{ day.ranking }}
                    </v-avatar>
                    <div v-else>{{ day.ranking }}</div>
                  </v-col>
                  <v-col
                    class="py-0 d-flex justify-center align-center"
                    cols="2"
                    :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap' : ''"
                  >
                    <div class="d-flex">
                      <v-avatar
                        color="grey lighten-1"
                        size="20"
                        class="white--text overline justify-center align-center"
                        :class="
                          show_rate_web && !$vuetify.breakpoint.xsOnly
                            ? 'ml-2 mr-1'
                            : 'mr-1'
                        "
                      >
                        <v-img
                          src="https://veetal-channel-logo-images.s3.eu-west-1.amazonaws.com/Booking.com.png"
                        ></v-img>
                      </v-avatar>
                      <a-kpi
                        :kpi="'min_price'"
                        :value="day.min_price"
                        :increment="false"
                        :simple="true"
                        class="font-weight-bold py-1"
                        v-if="day.min_price != '-'"
                      ></a-kpi>
                      <div v-else>{{ day.min_price }}</div>
                    </div>
                    <div class="d-flex ml-2">
                      <v-avatar
                        color="grey lighten-1"
                        size="20"
                        class=" white--text overline justify-center align-center mr-1"
                        v-if="show_rate_web"
                        >W
                      </v-avatar>
                      <a-kpi
                        :kpi="'min_price'"
                        :value="day.web_price"
                        :increment="false"
                        :simple="true"
                        class="font-weight-bold py-1"
                        :class="
                          getParityColor(day.web_price, day.min_price) +
                            '--text'
                        "
                        v-if="show_rate_web && day.web_price != '-'"
                      ></a-kpi>
                    </div>
                  </v-col>

                  <v-col
                    class="py-0 d-flex justify-center align-center"
                    cols="2"
                    v-if="show_parity"
                  >
                    <div
                      v-if="daily_parities"
                      class="d-flex justify-center align-center"
                    >
                      <div class="mx-1">
                        <v-avatar
                          :size="25"
                          :color="parity_colors['low']"
                          v-if="daily_parities[day.date]"
                        >
                          <span class="white--text">{{
                            daily_parities[day.date].low.value
                          }}</span>
                        </v-avatar>
                      </div>
                      <div class="mx-1">
                        <v-avatar
                          :size="25"
                          :color="parity_colors['critical']"
                          v-if="daily_parities[day.date]"
                        >
                          <span class="white--text">{{
                            daily_parities[day.date].critical.value
                          }}</span>
                        </v-avatar>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    class="py-0 d-flex justify-center align-center"
                    cols="2"
                  >
                    <a-kpi
                      :kpi="'min_price'"
                      :value="day.competitors_min_price"
                      :increment="false"
                      :simple="true"
                      class="py-1"
                    ></a-kpi>
                  </v-col>
                  <v-col
                    v-if="!show_parity"
                    class="py-0 d-flex justify-center align-center"
                    cols="3"
                  >
                    <v-list-item-subtitle
                      class="py-1"
                      :class="
                        day.room_type_name != '-'
                          ? 'text-center'
                          : 'text-center'
                      "
                    >
                      {{ day.room_type_name }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader type="table-thead"></v-skeleton-loader>
        <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import * as Utils from "@/utils.js";

export default {
  name: "a-rate-ranking-next-days",
  components: {
    aCard,
    aBaseComponent,
    aKpi
  },
  props: {},
  data: () => ({
    subscribed_modules: ["rate_daily_rates", "rate_competitors"],
    title: "ranking_next_days"
  }),
  methods: {
    seeDailyRate(date) {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        },
        query: {
          date: date
        }
      });
    },
    getFullDayName(date) {
      var d = Vue.moment(date);
      return this.$vuetify.breakpoint.xsOnly
        ? d.format("dd, DD MMMM")
        : d.format("dddd, DD MMMM");
    },
    getParityColor(web_price, ota_price) {
      return Utils.getParityColor(web_price, ota_price);
    },
    getColorFromRanking(ranking) {
      var color = "#93344a";
      if (ranking <= 2) {
        color = "#63a47c";
      }
      if (ranking > 2 && ranking <= 4) {
        color = "#318b89";
      }
      if (ranking > 4 && ranking <= 6) {
        color = "#e69812";
      }
      return color;
    },
    competitors_min_price(date) {
      var rankings = [];
      for (const competitor of this.competitors) {
        if (
          this.daily_rates.report &&
          this.daily_rates.report["competitor::" + competitor.id] &&
          this.daily_rates.report["competitor::" + competitor.id][date]
        ) {
          var item = {
            competitor_id: competitor.id,
            competitor_name: competitor.name,
            competitor_abbreviation: competitor.abbreviation,
            hotel_id: competitor.hotel_id,
            min_price: this.daily_rates.report["competitor::" + competitor.id][
              date
            ][0].min_price,
            ranking: this.daily_rates.report["competitor::" + competitor.id][
              date
            ][0].ranking
          };
          rankings.push(item);
        }
      }
      return rankings.length > 0 ? rankings : null;
    },
    competitors_min_price_avg(date) {
      var prices = [];
      for (const competitor of this.competitors) {
        if (
          this.daily_rates.report &&
          this.daily_rates.report["competitor::" + competitor.id] &&
          this.daily_rates.report["competitor::" + competitor.id][date]
        ) {
          prices.push(
            this.daily_rates.report["competitor::" + competitor.id][date][0]
              .min_price
          );
        }
      }
      var total = 0;
      return prices.length > 0 ? Math.min(...prices) : null;
    }
  },
  computed: {
    report() {
      return this.getHotelFromCompetitors && this.daily_rates.report
        ? this.daily_rates.report[
            "competitor::" + this.getHotelFromCompetitors.id
          ]
        : [];
    },
    daily_parities() {
      return this.report ? this.daily_rates.extras.daily_parities : [];
    },
    web_report() {
      return this.hotel_web && this.daily_rates.report
        ? this.daily_rates.report["competitor::" + this.hotel_web.id]
        : [];
    },
    next_days_ranking() {
      // Get next 7 days
      var days = [];

      if (this.groupDisplay) {
        // Group info
        for (var i = 0; i < 9; i++) {
          var date = Vue.moment()
            .add(i, "days")
            .format("Y-MM-DD");

          var item = {};
          item["date"] = date;
          item["friendly_date"] = this.getFullDayName(date);
          item["competitors_min_price"] = this.competitors_min_price(date);
          days.push(item);
        }
      } else {
        // Individual info
        for (var x = 0; x < 9; x++) {
          var day = Vue.moment()
            .add(x, "days")
            .format("Y-MM-DD");
          if (this.report && this.report[day]) {
            var repor = this.report[day][0];
            var itemm = repor;
            itemm["date"] = day;
            itemm["friendly_date"] = this.getFullDayName(day);
            itemm["competitors_min_price"] = this.competitors_min_price_avg(
              day
            );
            itemm["web_price"] =
              this.web_report && this.web_report[day]
                ? this.web_report[day][0]["min_price"]
                : null;

            days.push(itemm);
          } else {
            let itemm = {
              ranking: "-",
              min_price: "-",
              room_type_name: "-",
              date: day,
              friendly_date: this.getFullDayName(day),
              competitors_min_price: this.competitors_min_price_avg(day)
            };
            days.push(itemm);
          }
        }
      }
      return days;
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    show_parity() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.parity &&
            this.current_hotel.permissions.parity
        : false;
    },
    hotel_web() {
      return this.webs.length ? this.webs[0] : null;
    },
    ...mapState({
      parity_colors: state => state.parity_colors,
      competitors: state => state.rate.competitors,
      webs: state => state.parity.webs,
      daily_rates: state => state.rate.daily_rates
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      getHotelFromCompetitors: "getHotelFromCompetitors"
    })
  }
};
</script>

<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
