<template>
  <div class="a-parity-resume-graph">
    <div class="d-flex justify-center data-info">
      <div class="text-center black--text">
        <v-icon class="title d-block mb-3" color="grey lighten-1">
          {{ icon }}
        </v-icon>
        <span
          class="text-h4 d-block black--text font-weight-regular mb-1"
          style="font-family: 'Roboto Condensed' !important"
        >
          {{ value }}
        </span>
        <a-increment
          :value="vs_value"
          :kpi="kpi"
          :increment="increment"
          :increment_type="'percent'"
        ></a-increment>
      </div>
    </div>
    <zingchart
      :height="188"
      :width="width"
      :data="graph_settings"
      :series="graph_series"
      class="graph"
    ></zingchart>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aIncrement from "@/components/aIncrement";
import * as Utils from "@/utils.js";

export default {
  name: "a-parity-resume-graph",
  components: {
    aIncrement
  },
  props: {
    height: {
      type: [String, Number],
      default: "170"
    },
    width: {
      type: [String, Number],
      default: "100%"
    },
    type: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    kpi: {
      type: String,
      default: ""
    },
    values: {
      type: Object,
      default: () => {}
    },
    prefix: {
      type: String,
      default: "parity_pie_"
    }
  },
  computed: {
    kpi_type() {
      return this.get_kpi(this.kpi).data_format;
    },
    value() {
      return (
        Utils.format_kpi_value(this.values["value"], this.kpi_type, this.l) +
        "%"
      );
    },
    vs_value() {
      return this.values["value_to_compare"];
    },
    increment() {
      return this.values["value_increment"];
    },
    graph_settings() {
      var tooltip = this.chart_settings.tooltip;
      tooltip["align"] = "center";
      tooltip["text"] =
        "<span style='font-family:\"Roboto Condensed\";font-size: 20px;color: %backgroundcolor;'>%v%</span><br><span style='font-size: 13px;font-family:\"Roboto Condensed\";'>%t</span>";
      return {
        type: "ring",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "transparent",
        plot: {
          detach: false,
          pieTransform: "flow=99",
          valueBox: {
            visible: false
          },
          animation: {
            delay: 0,
            effect: "ANIMATION_EXPAND_VERTICAL",
            method: "ANIMATION_LINEAR",
            sequence: "ANIMATION_BY_PLOT",
            speed: "600"
          },
          refAngle: 270,
          slice: 74,
          tooltip: tooltip
        },
        plotarea: {
          margin: "0px 0px 0px 0px"
        }
      };
    },
    graph_series() {
      var series = [];
      for (let parity_code in this.parity_colors) {
        series.push({
          values: [this.values[parity_code]["percent"]],
          backgroundColor: this.parity_colors[parity_code],
          text:
            "<b style='font-size: 13px;font-family:\"Roboto Condensed\";font-weight: bold'>" +
            this.values[parity_code]["value"] +
            "</b> " +
            this.$t(this.prefix + parity_code)
        });
      }
      return series;
    },
    ...mapState({
      emotions: state => state.emotions,
      parity_colors: state => state.parity_colors,
      chart_settings: state => state.chart_settings
    }),
    ...mapGetters({
      l: "current_hotel_language",
      get_kpi: "get_kpi"
    })
  }
};
</script>

<style scoped>
.a-parity-resume-graph {
  position: relative;
}
.data-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.graph {
  position: relative;
  margin: -10px 0;
}
</style>
