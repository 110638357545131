<template>
  <!-- RATE DATE TO COMPARE -->
  <div id="dashboard_rate">
    <v-row v-if="highlights_info" class="mt-n4">
      <v-col cols="12" lg="6" class="pt-0">
        <a-parity-dashboard-resume
          v-if="show_rate_web || show_parity"
          ref="a-parity-dashboard-resume"
        >
        </a-parity-dashboard-resume>
        <a-rate-dashboard-resume v-else ref="a-rate-dashboard-resume">
        </a-rate-dashboard-resume>
      </v-col>
      <v-col cols="12" lg="6" class="pt-0">
        <a-rate-ranking-next-days ref="a-rate-ranking-next-days">
        </a-rate-ranking-next-days>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <a-card :title="$t(title)" v-if="high_priority_highlights.length > 0">
          <a-base-component :subscribed_modules="subscribed_modules">
            <template v-slot:default>
              <a-hightlight-item
                v-for="(highlight, index) in high_priority_highlights"
                :key="'highlight_' + index"
                :hightlight="highlight"
                :clas="'ml-3'"
                @loadRate="loadRate"
                @loadParity="loadParity"
              ></a-hightlight-item>
              <v-btn
                outlined
                rounded
                color="info"
                @click="seeDailyHighlight"
                class="mt-2 mb-1 ml-3"
                >{{ $t("to_review") }}</v-btn
              >
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
            </template>
          </a-base-component>
        </a-card>
        <a-card :title="$t(title)" v-else>
          <a-base-component :subscribed_modules="subscribed_modules">
            <template v-slot:default>
              <v-card-subtitle class="px-3 pt-4 pb-6 text-center">
                <v-icon color="grey lighten-2" class="display-4"
                  >mdi-check-bold</v-icon
                ><br />
                {{ $t("nothing_to_review") }}
              </v-card-subtitle>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
            </template>
          </a-base-component>
        </a-card>
      </v-col>
      <v-col cols="12" lg="6">
        <a-reputation-dashboard-resume ref="a-reputation-dashboard-resume">
        </a-reputation-dashboard-resume>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import aReputationDashboardResume from "@/components/reputation/aReputationDashboardResume";
import aParityDashboardResume from "@/components/parity/aParityDashboardResume";
import aRateDashboardResume from "@/components/rate/aRateDashboardResume";
import aRateRankingNextDays from "@/components/rate/aRateRankingNextDays";
import aHightlightItem from "@/components/aHightlightItem";
var numeral = require("numeral");

export default {
  name: "dashboard-rate-app",
  components: {
    aCard,
    aHightlightItem,
    aBaseComponent,
    aReputationDashboardResume,
    aParityDashboardResume,
    aRateDashboardResume,
    aRateRankingNextDays
  },
  data: vm => ({
    page: 1,
    perPage: 8,
    init_date: null,
    subscribed_modules: [
      "rate_available_rate_extractions",
      "highlight_list",
      "highlight_info",
      "bi_report",
      "parity_webs"
    ],
    title: "we_suggest_you_to_review"
  }),
  watch: {
    available_rate_extractions() {
      if (!this.extraction_id) {
        this.setDefault();
      }
    }
  },
  methods: {
    getDateFromId(id) {
      return (
        this.available_rate_extractions &&
        this.available_rate_extractions.find(node =>
          node.extractions.find(node => node.id == id)
        ).date
      );
    },
    seeReputation() {
      this.$router.push({
        name: "reputation",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        }
      });
    },
    seeHotelDailyRate(hotel_id, date) {
      const hotel = this.getHotelById(hotel_id);
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: {
          date: date
        }
      });
    },
    seeDailyRate(date) {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        },
        query: {
          date: date
        }
      });
    },
    seeDailyHighlight() {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        },
        query: {
          highlights: true
        }
      });
    },
    seeHighlightPriority(priority) {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        },
        query: {
          highlights: true,
          highlight_priority: JSON.stringify([priority])
        }
      });
    },
    loadParity(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "parity",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    loadRate(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    goToRatePage() {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        }
      });
    },
    setDefault() {
      /* CHECK EACH PARAM */
      this.extraction_id =
        this.query_params.extraction_id &&
        this.extractionAllowedId(this.query_params.extraction_id)
          ? this.query_params.extraction_id
          : this.defaultExtractionId();

      this.init_date =
        this.query_params.init_date &&
        this.initAllowedDate(this.query_params.init_date)
          ? this.query_params.init_date
          : this.defaultInitDate();

      let vs_id =
        this.query_params.vs_id && this.vsAllowedId(this.query_params.vs_id)
          ? this.query_params.vs_id
          : this.defaultVsId();

      this.mode = "rate";

      let adults =
        this.query_params.adults &&
        [0, 2, 3, 4, 5, 6, 7].indexOf(parseInt(this.query_params.adults)) >= 0
          ? parseInt(this.query_params.adults)
          : 2;

      let childs =
        this.query_params.childs &&
        [0, 1, 2].indexOf(parseInt(this.query_params.childs)) >= 0
          ? parseInt(this.query_params.childs)
          : 0;

      let regime = this.query_params.regime
        ? JSON.parse(this.query_params.regime.slice(1, -1))
        : [];

      let cancel_policy = this.query_params.cancel_policy
        ? JSON.parse(this.query_params.cancel_policy.slice(1, -1))
        : [];

      if (this.query_params.highlights == "true") {
        this.showRateHighlight(true);
      }
      this.$store.dispatch("setRateFilters", {
        extraction_id: this.extraction_id,
        vs_id: vs_id,
        pax: adults,
        childs: childs,
        regime: regime,
        cancel_policy: cancel_policy
      });

      this.$store.dispatch("setHighlightsExtractionId", this.extraction_id);
    },
    // FILTERS
    extractionAllowedId(v) {
      return this.available_rate_import_ids &&
        this.available_rate_import_ids.find(id => id == v)
        ? true
        : false;
    },
    defaultExtractionId() {
      return this.available_rate_import_ids
        ? this.available_rate_import_ids[0]
        : "";
    },
    initAllowedDate(v) {
      // Must return if equal or bigger than selected extraction_date
      var date = Vue.moment(v);
      var extraction_date = Vue.moment(this.extraction_date);
      return date.diff(extraction_date, "days") >= 0 ? true : false;
    },
    defaultInitDate() {
      return this.extraction_date;
    },
    vsAllowedId(v) {
      // Must return if lower than selected extraction_id and extractionAllowedId(v)
      return v < this.extraction_id && this.extractionAllowedId(v)
        ? true
        : false;
    },
    // TODO: esborrar defaultVsDate?
    defaultVsDate() {
      // Must return max from available_rate_import_dates and lower than extraction_date
      var vm = this;
      return this.current_hotel &&
        this.current_hotel.settings &&
        this.current_hotel.settings.available_rate_import_dates
        ? this.current_hotel.settings.available_rate_import_dates.find(date =>
            vm.vsAllowedDate(date)
          )
        : "";
    },
    defaultVsId() {
      // Must return max from available_rate_import_ids and lower than extraction_id
      var vm = this;
      return this.available_rate_import_ids
        ? this.available_rate_import_ids.find(id => vm.vsAllowedId(id))
        : "";
    },
    formatDate(date) {
      return date
        ? Vue.moment(date)
            .format("D MMM YYYY")
            .replace(".", "")
            .toUpperCase()
        : "";
    },

    handle_function_call(function_name, function_params, highlight) {
      this[function_name](highlight, function_params);
    },
    isDone(highlight) {
      return this.current_user.settings &&
        this.current_user.settings.highlights_done &&
        this.current_user.settings.highlights_done.indexOf(highlight.id) >= 0
        ? true
        : false;
    },
    getColorFromPriority(priority) {
      var p = this.priorities.find(p => p.id == priority);
      return p ? p.color : null;
    },
    getIconFromPriority(priority) {
      var p = this.priorities.find(p => p.id == priority);
      return p ? p.icon : null;
    },
    getPriorityById(id) {
      return this.priorities.find(p => p.id == id);
    },
    getHighlightTypeById(id) {
      return this.highlight_types
        ? this.highlight_types.find(t => t.id == id)
        : [];
    },
    getHighlightByTypeId(id) {
      return this.highlights_by_type.find(h => h.highlight_type_id == id);
    },
    getTotalHighlightsByTypeId(id) {
      const highlight = this.highlights_by_type.find(
        h => h.highlight_type_id == id
      );
      var total = 0;
      if (highlight) {
        for (var p in highlight.priorities) {
          total += highlight.priorities[p].total;
        }
      }
      return total;
    },
    seeHighlight(highlight) {
      //We use 'user' for dispatching when this.current_user = user;
      var user = JSON.parse(JSON.stringify(this.current_user));

      //We verify it exists and it's an array
      var highlights_done =
        this.current_user.settings && this.current_user.settings.highlights_done
          ? JSON.parse(
              JSON.stringify(this.current_user.settings.highlights_done)
            )
          : [];
      if (Object.keys(user.settings).length === 0) {
        user.settings = {};
      }
      if (highlights_done.indexOf(highlight.id) < 0) {
        // We add it
        highlights_done.push(highlight.id);
        user.settings.highlights_done = highlights_done;
      } else {
        // We remove it
        highlights_done.splice(highlights_done.indexOf(highlight.id), 1);
        user.settings.highlights_done = highlights_done;
      }

      // We update it here, for UX improve
      this.current_user = user;

      // We save it in the server, in background (no waiting time for user)
      this.$store.dispatch("updateUser", this.current_user);
    },
    ...mapActions(["showRateHighlight"])
  },
  mounted() {
    this.$nextTick(function() {
      if (this.current_hotel) {
        this.setDefault();
      }
    });
  },
  computed: {
    booking() {
      return this.get_ota("booking");
    },
    extraction_id: {
      get() {
        return this.$store.state.extraction_id;
      },
      set(value) {
        this.$store.dispatch("setExtractionId", value);
      }
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    highlight_type_pages() {
      var pages = [];
      if (this.highlights_by_type) {
        for (var i = 0; i < this.highlights_by_type.length; i++) {
          pages.push(i);
        }
      }
      return pages;
    },
    highlight_types() {
      return this.highlights_info ? this.highlights_info.highlight_types : [];
    },
    visibleItems() {
      return this.highlight_type_pages
        ? this.highlight_type_pages.slice(
            (this.page - 1) * this.perPage,
            this.page * this.perPage
          )
        : [];
    },
    visibleHighlightTypes() {
      return this.highlights_by_type
        ? this.highlights_by_type.slice(
            (this.page - 1) * this.perPage,
            this.page * this.perPage
          )
        : [];
    },
    high_priority_highlights() {
      return this.highlights
        .filter(highlight => !this.isDone(highlight))
        .sort((a, b) => b.priority - a.priority)
        .slice(0, 4);
    },
    available_rate_import_ids() {
      // In this.current_hotel.settings.available_rate_extractions we have a list of [{date: "2020-01-01", extractions: {id: 1234, time: "12:01:01"}}, {...}]
      // From there we take a list of ids to make an easy validation
      let ids = [];
      if (this.available_rate_extractions) {
        ids = this.available_rate_extractions.reduce(
          (ids, cur) => ids.concat(cur.extractions.map(imp => imp.id)),
          []
        );
      }
      return ids;
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    show_parity() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.parity &&
            this.current_hotel.permissions.parity
        : false;
    },
    ...mapState({
      highlights: state => state.highlight.list,
      highlights_info: state => state.highlight.info,
      priorities: state => state.highlight_priorities,
      query_params: state => state.route.query,
      daily_rates: state => state.rate.daily_rates,
      available_rate_extractions: state => state.rate.available_rate_extractions
    }),
    ...mapGetters({
      getHighlightTypes: "getHighlightTypes",
      getHighlightsByType: "getHighlightsByType",
      current_hotel: "current_hotel",
      getHotelById: "getHotelById",
      highlights_by_priority: "highlights_by_priority",
      highlights_by_type: "highlights_by_type",
      get_ota: "get_ota"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
@media (max-width: 600px) {
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 46px !important;
    padding: 0 5px !important;
  }
}
</style>
