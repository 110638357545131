<template>
  <a-card :title="title">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row>
          <v-col
            cols="12"
            sm="8"
            :lg="lg_left"
            xl="6"
            class="text-center pt-12 mt-0 pb-0"
          >
            <v-row>
              <v-col cols="6">
                <a-ellipse-kpi
                  ref="a-ellipse-hawg-rating"
                  :kpi="'hawg_rating'"
                ></a-ellipse-kpi>
              </v-col>
              <v-col cols="6">
                <a-reputation-reviews-graph
                  :icon="'mdi-comment-multiple-outline'"
                  :increment="bi_report.increments['new_reviews']"
                  :value="bi_report.report['new_reviews']"
                  :vs_value="bi_report.data_to_compare['new_reviews']"
                  :kpi="'new_reviews'"
                ></a-reputation-reviews-graph>
                <a-label class="mt-2 text-center">
                  {{ $t("new_reviews") }}
                </a-label>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            :lg="lg_right"
            xl="6"
            class="py-0"
            v-if="!is_lg_little"
          >
            <a-reputation-list
              class="mt-n1"
              :items="items"
              :kpi="'rating'"
            ></a-reputation-list>
          </v-col>
        </v-row>
        <v-alert
          color="red"
          text
          dense
          class="white--text mt-4 mx-2 px-6 text-left"
        >
          <v-row align="center">
            <v-col class="shrink py-0">
              <span class="text-h4">
                {{ bi_report.report.answerable - bi_report.report.answered }}
              </span>
            </v-col>
            <v-col class="grow py-0 pl-0">
              {{ $t("unanswered_reviews") }}
            </v-col>
            <v-col class="shrink py-0">
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                outlined
                rounded
                color="red"
                @click="goToReputation"
              >
                {{ $t("see") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </template>
      <template v-slot:loading>
        <v-row class="text-center" align="center">
          <v-col cols="4">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              type="list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="list-item-avatar-two-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ACard from "@/components/aCard.vue";
import ABaseComponent from "@/components/aBaseComponent.vue";
import AEllipseKpi from "@/components/aEllipseKpi.vue";
import AReputationReviewsGraph from "@/components/reputation/aReputationReviewsGraph.vue";
import ALabel from "@/components/aLabel.vue";
import AReputationList from "@/components/reputation/aReputationList.vue";

export default {
  name: "a-reputation-dashboard-resume",
  components: {
    AReputationList,
    ALabel,
    AReputationReviewsGraph,
    AEllipseKpi,
    ABaseComponent,
    ACard
  },
  data: vm => ({
    title: "reputation",
    subscribed_modules: ["bi_report"]
  }),
  methods: {
    goToReputation() {
      this.$router.push({
        name: "reputation",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        }
      });
    }
  },
  mounted() {},
  computed: {
    is_lg_little() {
      // Defines when we are lg and bigger than 1450
      return this.app_width < 1450 && this.$vuetify.breakpoint.lgOnly;
    },
    is_lg_big() {
      // Defines when we are lg and bigger than 1450
      return this.app_width > 1450 && this.$vuetify.breakpoint.lgOnly;
    },
    lg_left() {
      return this.is_lg_big ? 8 : 12;
    },
    lg_right() {
      return this.is_lg_big ? 4 : 12;
    },
    items() {
      var items = [];
      for (var ota of this.otas) {
        items.push({
          name: ota.code,
          logo: ota.logo,
          value:
            parseFloat(this.bi_report.report[ota.code + "_rating"]) || null,
          vs_value:
            parseFloat(this.bi_report.data_to_compare[ota.code + "_rating"]) ||
            null,
          increment:
            parseFloat(this.bi_report.increments[ota.code + "_rating"]) || null
        });
      }
      return items;
    },
    ...mapState({
      bi_report: state => state.bi.report,
      bi_daily_report: state => state.bi.daily_report,
      otas: state => state.otas,
      emotions: state => state.emotions,
      app_width: state => state.app_width
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style scoped></style>
